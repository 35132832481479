import React from 'react';
import Products from '../../components/Products';
// import Services from '../../components/Services';
import Layout from '../../components/Layout';
import styled from 'styled-components';
import '../../assets/css/index.css';

const properties = () => {
  return (
    <Main>
      <Layout>
        <Products />
        {/* <Services /> */}
      </Layout>
    </Main>
  );
};

const Main = styled.main`
  width: 100%;
  position: relative;
`;

export default properties;
